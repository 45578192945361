export const fr =
{
    "startsWith": "Commence par",
    "contains": "Contient",
    "notContains": "Ne contient pas",
    "endsWith": "Se termine par",
    "equals": "Egal à",
    "notEquals": "Différent de",
    "noFilter": "Aucun filtre",
    "lt": "Inférieur à",
    "lte": "Inférieur ou égal à",
    "gt": "Supérieur à",
    "gte": "Supérieur ou égal à",
    "dateIs": "La date est",
    "dateIsNot": "La date n'est pas",
    "dateBefore": "Avant le",
    "dateAfter": "Après le",
    "custom": "Personnalisé",
    "clear": "Effacer",
    "apply": "Appliquer",
    "matchAll": "Correspond à tous",
    "matchAny": "Au moins un Correspond",
    "addRule": "Ajouter une règle",
    "removeRule": "Retirer une règle",
    "accept": "Oui",
    "reject": "Non",
    "choose": "Choisir",
    "upload": "Envoyer",
    "cancel": "Annuler",
    "dayNames": ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    "dayNamesShort": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    "dayNamesMin": ["Di", "Lu", "Mar", "Mer", "Je", "Ve", "Sa"],
    "monthNames": ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    "monthNamesShort": ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jui", "Août", "Sept", "Oct", "Nov", "Dec"],
    "today": "Aujourd'hui",
    "weekHeader": "Sem",
    "firstDayOfWeek": 1,
    "dateFormat": "dd/mm/yy",
    "weak": "Faible",
    "medium": "Moyen",
    "strong": "Fort",
    "passwordPrompt": "Saisissez un mot de passe",
    "emptyMessage": "",
    "emptyFilterMessage": "Aucun résultat trouvé",
    "aria": {
        "trueLabel": "Vrai",
        "falseLabel": "Faux",
        "nullLabel": "Aucune sélection",
        "pageLabel": "Page",
        "firstPageLabel": "Première Page",
        "lastPageLabel": "Dernière Page",
        "nextPageLabel": "Page Suivante",
        "previousPageLabel": "Page Précédente"
    },
    "alreadyConnected": "Vous êtes déjà connecté !",
    "send": "Envoyer",
    "keywordSearch": "Filtrer par mot-clé",
    "submit": "Valider",
    "renew": "Renouveller",
    "allreadyConnected": "Vous êtes déjà connecté !",
    "navBack": "retour",
    "withSuccess": "Opération effectuée avec succès",
    "withError": "une erreur est survenue !",
    "confirmDelete": "Confirmez suppression",
    "questionDelete": "Voulez vous supprimez",
    "sendForm": "Envoyer formulaire",
    "close": "Fermer",
    "connect": "Se connecter",
    "new": "créer "
}


export const de = {
   
        "startsWith":"startet mit",
        "contains":"enhält",
        "notContains":"enthält nicht",
        "endsWith":"endet mit",
        "equals":"gleich",
        "notEquals":"nicht gleich",
        "noFilter":"kein Filter",
        "lt":"weniger als",
        "lte":"weniger oder gleich wie",
        "gt":"größer als",
        "gte":"größer oder gleich wie",
        "dateIs":"Datum ist",
        "dateIsNot":"Datum ist nicht",
        "dateBefore":"Datum ist vor",
        "dateAfter":"Datum ist nach",
        "custom":"Benutzerdefiniert",
        "apply":"Übernehmen",
        "matchAll":"Passt auf alle",
        "matchAny":"Passt auf einige",
        "addRule":"Regel hinzufügen",
        "removeRule":"Regel entfernen",
        "accept": "Ja",
        "reject": "Nein",
        "choose": "Auswählen",
        "upload": "Upload",
        "cancel": "Abbruch",
        "dayNames": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        "dayNamesShort": ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
        "dayNamesMin": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        "monthNames": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        "monthNamesShort": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dez"],
        "today": "Heute",
        "clear": "Löschen",
        "weekHeader": "Wo",
        "firstDayOfWeek": 1,
        "dateFormat": "dd.mm.yy",
        "weak": "Schwach",
        "medium": "Mittel",
        "strong": "Stark",
        "passwordPrompt": "Passwort eingeben",
        "emptyFilterMessage":"Keine Einträge gefunden",
        "emptyMessage": "Keine Einträge gefunden",
        "aria": {
            "trueLabel": "Richtig",
            "falseLabel": "Falsch",
            "nullLabel": "Nicht selektiert",
            "pageLabel": "Seite",
            "firstPageLabel": "Erste Seite",
            "lastPageLabel": "Letzte Seite",
            "nextPageLabel": "Nächste Seite",
            "previousPageLabel": "Vorherige Seite"
        }
    
}