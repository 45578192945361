import * as React from 'react'
import { addLocale } from 'primereact/api';

//import "primereact/resources/themes/lara-light-purple/theme.css";  //theme
import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"
import { fr, de } from './utils/primeLanguage';
//import { MediaContextProvider } from './App/media'
import './index.css'
import './step.css'

addLocale('fr', fr)
addLocale('de', de)
//locale('fr')

const Layout = React.lazy(() => import('./App/components/common/Layout'))
const ApiProvider = React.lazy(() => import('./App/providers/Api'))
const RtqProvider = React.lazy(() => import('./App/providers/Rtq'))
const ConfigProvider = React.lazy(() => import('./App/providers/Config'))
const RouteProvider = React.lazy(() => import('./App/providers/Route'))
const RenderRoute = React.lazy(() => import('./App/routes/RenderRoute'))
const ToasterProvider = React.lazy(() => import('./App/providers/Toaster'))
const MediaProvider = React.lazy(() => import('./App/providers/MediaProvider'))

function App(props) {
    //console.log('in app')
    const { dehydratedState, token, useragent } = props
    const [auth, setAuth] = React.useState()
    //console.log(token)
    return (
        <React.Suspense>
            <ApiProvider token={token}>
                <React.Suspense>
                    <RtqProvider dehydratedState={dehydratedState}>
                        <React.Suspense>
                            <ConfigProvider setAuth={setAuth} token={token}>
                                <React.Suspense>
                                    <ToasterProvider>
                                        <React.Suspense>
                                            <RouteProvider >
                                                <React.Suspense>
                                                    <MediaProvider useragent={useragent}>
                                                        <React.Suspense>
                                                            <Layout>
                                                                <React.Suspense>
                                                                    <RenderRoute />
                                                                </React.Suspense>
                                                            </Layout>
                                                        </React.Suspense>
                                                    </MediaProvider>
                                                </React.Suspense>
                                            </RouteProvider>
                                        </React.Suspense>
                                    </ToasterProvider>
                                </React.Suspense>
                            </ConfigProvider>
                        </React.Suspense>
                    </RtqProvider>
                </React.Suspense>
            </ApiProvider>
        </React.Suspense>
    );
}

/**
 *  <RenderRoute routes={buildedRoutes} />
 * <RouteProvider routes={buildRoutes} dynamicRoutes={dynamicRoutes} buildRoutes={buildRoutes}>
 */
export default App;

