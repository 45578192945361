import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';


const dehydratedState = window.__REACT_QUERY_STATE__
const token = window.__TOKEN__
const useragent = window.__USERAGENT__

const Main = () => {
  return (
    <BrowserRouter>
      <React.Suspense>
        <App dehydratedState={dehydratedState} token={token} useragent={useragent}/>
      </React.Suspense>

    </BrowserRouter>
  )
}
const container = document.getElementById('root');

hydrateRoot(container, <Main />)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
